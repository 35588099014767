.team {
  width: 70%;
  margin: 0 auto;
  padding: 60px 0;
  position: relative;
  @media only screen and (max-width: 1024px) {
    width: 90%;
  }

  h1 {
    font-weight: 800;
    font-size: 48px;
    color: #18191f;

    margin-bottom: 10px;

    @media only screen and (max-width: 425px) {
      font-size: 35px;
    }
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
    @media only screen and (max-width: 560px) {
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }

  .container {
    height: 316px;
    width: 300px;
    position: relative;
    margin: 30px;

    @media only screen and (max-width: 1024px) {
      height: 220px;
      width: 220px;
    }
  }

  .box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 30px;
    background: #fcf3e1;
  }

  .flex-bottom {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .overlay {
    z-index: 9;
    margin: 20px;
    background: #e9b135;
    border-radius: 30px;
  }

  &-name {
    font-weight: bold;
    font-size: 22.9381px;
    line-height: 37px;
    color: #e9b135;
  }

  &-title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
  }

  &-image {
    width: 100%;
    height: 100%;
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    border-radius: 50%;
    padding: 3px;
    background-color: #0177b5;
    margin-right: 10px;
  }
}
