@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;800&family=Poppins:wght@100;200;300;400;500;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap");
@import "./variables.scss";
@import "./App.scss";
@import "../../components/Homepage/LandingPage/LandingPage.scss";
// @import "./Navbar.scss";
@import "../../components/Homepage/BecomeAnAgent/BecomeAnAgent.scss";
@import "../../components/Homepage/AgentBenefits/AgentBenefits.scss";
@import "../../components/Homepage/Accordion/Accordion.scss";
@import "../../components/navbar/Navbar.scss";
@import "../../components/Homepage/InsideAppSection/InsideAppSection.scss";
@import "../../components/Homepage/TeamSection/TeamSection.scss";
@import "../../components/Homepage/OurClients/OurClients.scss";
@import "../../components/Homepage/TrustedBy/TrustedBy.scss";
@import "../../components/AboutUs/AboutUs.scss";
@import "../../components/Homepage/Footer/Footer.scss";
@import "./Technology.scss";
@import "./JoinForces.scss";
@import "./Services.scss";
@import "../../Pages/Blog/Blog.scss";

body {
  margin: 0;
  font-family: "Palanquin", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  font-family: "Plus Jakarta Sans", sans-serif !important;
}
.App {
  overflow-x: visible;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; //10px/16px = 62.5% => 1rem = 10px
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
  outline: none;
  margin: 0;
  padding: 0;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateX(0);
  }
}
