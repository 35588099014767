$app-primary: #e3a721;
$color-black: #000000;
$color-white: #ffffff;
$color-grey: #f5f5f5;
$color-text: #18191f;
$regxta-black: #494949;

$grey07: #f8f8f8;

// media queries
@mixin mq($arg) {
  @media screen and (max-width: $arg) {
    @content;
  }
}

@mixin container {
  width: 80%;
  margin: auto;
  max-width: 1920px;
  margin: auto;
  @include mq(1024px) {
    width: 90%;
  }
}

@mixin accordion_box {
  background-color: $grey07;
  border-radius: 16px;
  border: none;
  box-shadow: none;
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    font-feature-settings: "salt" on, "liga" off;
    color: #20202f;
  }
  .content {
    font-weight: normal;
    font-size: 17.6683px;
    line-height: 30px;
    font-feature-settings: "salt" on, "liga" off;
    color: #18191f;
    text-align: left;
    padding: 10px 0 10px 16px;
    animation: fadeInOpacity 0.6s ease-in-out;
    @include mq(768px) {
      font-size: 14px;
      line-height: 24px;
    }
  }
  .accordion_title {
    min-height: unset !important;
    .MuiAccordionSummary-contentGutters {
      margin: 0 !important;
      padding: 8px 0;
    }
  }
  .MuiAccordionDetails-root {
    padding-top: 0 !important;
  }
  &::before {
    display: none;
  }
}
