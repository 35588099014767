.technology-container {
  background: linear-gradient(135deg, #fff5df 0.01%, #dbd2ff 100%);
  min-height: auto;
  display: flex;
  flex-direction: column;
  // width: 70%;
  width: 100%;
  margin: 0 auto;
  // margin-bottom: 100px;
  // padding-top: 100px;
  padding-bottom: 150px;
  justify-content: space-between;
  align-items: center;

  .text_wrapper {
    width: 80%;
    margin-top: 100px;
    @media only screen and (max-width: 640px) {
      width: 100%;
    }
    .top-text-area {
      align-items: center;
      padding: 100px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .coming-soon-text {
        color: #ea592f;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 20px;

        @media only screen and (max-width: 768px) {
          font-size: 16px;
        }
      }

      @media only screen and (max-width: 960px) {
        flex-direction: column;
        font-size: 16px;
        padding: 50px 0px;
        &:nth-child(even) {
          flex-direction: row;
          flex-wrap: wrap-reverse;
        }
      }

      img {
        width: 95%;
        &.border-img {
          border: 2.5px solid black;
          border-radius: 12px;
        }
      }
    }
  }

  .left-container {
    letter-spacing: 0em;
    text-align: left;
    // margin-left: 30px;
    // min-width: 470px;
    // min-height: 200px;

    .left-container-header {
      color: #1e1e1e;
      font-size: 40px;
      font-weight: 700;
      text-transform: capitalize;

      @media only screen and (max-width: 760px) {
        font-size: 32px;
      }
    }

    .left-container-text {
      margin-top: 24px;
      color: #494949;
      font-size: 20px;

      @media only screen and (max-width: 760px) {
        font-size: 16px;
      }
    }

    @media only screen and (max-width: 760px) {
      padding: 0;
      margin-left: 0;
      min-width: 260px;

      img {
        width: 95%;
        margin: auto;
      }
    }
  }

  .right-container {
    text-align: left;
    margin-left: 100px;
    min-width: 470px;
    min-height: 200px;
    &.right-second {
      margin-left: 0;
      margin-right: 100px;
      @media only screen and (max-width: 960px) {
        // margin-right: 0;
        // margin-left: 0 !important;
        margin: 0 auto;
        padding-top: 4rem;
      }
    }

    @media only screen and (max-width: 960px) {
      margin-left: 0;
      margin-right: 0;
      min-width: 270px;
      padding-top: 3rem;

      img {
        width: 95%;
        margin: auto;
      }
    }

    .right-container-header {
      color: #1e1e1e;
      font-size: 40px;
      font-weight: 700;
      text-transform: capitalize;

      @media only screen and (max-width: 760px) {
        font-size: 32px;
      }
    }

    .right-container-text {
      margin-top: 20px;
      color: #494949;
      font-size: 20px;

      @media only screen and (max-width: 760px) {
        font-size: 16px;
      }
    }
  }

  @media only screen and (max-width: 760px) {
    padding: 0 40px;
  }
  @media only screen and (max-width: 460px) {
    padding: 0 16px;
  }
}
