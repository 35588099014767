@import "../../../styles/sass/_variables.scss";

.pd_price_faq {
  background-color: #fefefe;
  padding: 20px 0;
  background-image: url("../../../../public/images/faq-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  .faq_wrapper {
    @include container;

    h3 {
      font-style: normal;
      font-weight: 800;
      font-size: 40px;
      line-height: 54px;
      font-feature-settings: "salt" on, "liga" off;
      color: $color-text;
      padding: 40px 0;
      @include mq(768px) {
        font-size: 30px;
        line-height: 40px;
      }
    }
    .accordion_section {
      margin-top: 52px;
      @include mq(768px) {
        padding: 0 5%;
        margin-top: 24px;
      }
    }
    .accordion_box {
      @include accordion_box;
      padding: 16px;
      margin-bottom: 1.5rem;
      box-shadow: 0px 2.4px 4.8px rgba(0, 0, 0, 0.07),
        /* First shadow */ 0px 0.9px 7px rgba(0, 0, 0, 0.07); /* Second shadow */
      display: block;
      height: auto;
      transition: height 5s ease-in-out;

      //   .accordion_title {
      //     padding-left: 0;
      //     padding-right: 0;

      //     @include mq(649px) {
      //       font-size: 14px;
      //     }
      //   }
      .MuiAccordionDetails-root {
        padding-left: 0 !important;
      }
    }
  }
  .faq-custom {
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 21.4415px;
      line-height: 25px;
      font-feature-settings: "salt" on, "liga" off;
      color: #20202f;
      text-align: left;
      @include mq(768px) {
        font-size: 18px !important;
        font-size: 16px !important;
        line-height: 22;
        letter-spacing: -1.5%;
      }
    }
    .rotate {
      transform: rotate(45deg);
    }
  }
  .footer {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: $color-black;
    max-width: 80%;
    margin: 50px auto;

    .orange-text {
      color: $app-primary;
      font-size: 16px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0em;
    }

    @media only screen and (max-width: 768px) {
      font-size: 19px;
    }
  }
}
