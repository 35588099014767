@import "../../../styles/sass/_variables.scss";

.content2 {
  overflow: visible !important;
  margin-top: 10rem;
  @include mq(640px) {
    margin-top: 50px;
  }
  .why-we-different-par-one-right {
    @media only screen and (max-width: 768px) {
      width: 92%;
    }
  }
  .header_wrapper {
    h1 {
      font-size: 40px;
      font-weight: 600;
      line-height: 50px;
      @media only screen and (max-width: 768px) {
        font-size: 32px;
      }
    }
    p {
      font-size: 20px;
      line-height: 25px;
      width: 95%;
      margin: auto;
      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
      span {
        font-weight: 700;
      }
    }
  }
  .first_wrapper {
    position: relative;
  }
  .scroll_wrapper {
    .why-we-different-container {
      display: flex;
      width: 80%;
      max-width: 1440px;
      background-color: #ffffd3;
      padding: 52px;
      border-radius: 32px;
      position: sticky;
      top: 10rem;
      z-index: 2;
      @include mq(640px) {
        padding: 24px;
        width: 90%;
        border-radius: 24px;
        top: 2rem;
      }

      &:nth-child(2) {
        z-index: 3;
        background-color: #f7e9ff;
      }
      &:nth-child(3) {
        z-index: 4;
        background-color: #ebffeb;
        top: 0;
      }
      transition: all 0.5s;
    }
  }

  .why-we-different-par-one-right {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq(820px) {
      width: 100%;
    }
  }

  .why-we-different-image {
    width: 80%;
    transition: opacity 1s ease-in-out;
    @include mq(768px) {
      width: 100%;
    }
  }

  .why-we-different-text-area {
    width: 55%;
    margin-top: 0;
    @include mq(820px) {
      width: 100% !important;
    }
  }

  .text-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include mq(768px) {
      height: auto;
    }
  }

  .why-we-different-header {
    font-size: 24px !important;
    margin-bottom: 0px !important;
    text-align: start !important;
  }

  .why-we-different-text {
    font-size: 1.2em;
    margin-bottom: 12px;
    margin: 10px 0 !important;
    &:first-child {
      margin-bottom: 0;
    }
  }

  .tick-icon {
    margin-right: 10px;
  }
}
