

.text-and-image-container{
@media only screen and (max-width: 760px) {
        flex-direction: column;
    }
        
}
.img-area {
    align-items: flex-end;

    @media only screen and (max-width: 760px) {
        align-items: flex-start;

        img {
            width: 95%;
        }
    }
}