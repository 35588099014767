@import "../../../styles/sass/_variables.scss";

.landing-container {
  background-color: #212121;
  display: flex;
  flex-direction: column;
  padding-top: 200px;
  padding-bottom: 150px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  color: #f3f3f3;
  height: auto;
  @media only screen and (max-width: 1024px) {
    height: auto;
  }
  @media only screen and (max-width: 768px) {
    padding-bottom: 100px;
    height: auto;
  }

  .circle-lines {
    align-self: flex-start;
    margin-left: -20px;
    @media only screen and (max-width: 700px) {
      display: none;
    }
  }
  .angle_wrapper {
    display: relative;
    background: lime;
    width: 100%;
    .triangles {
      align-self: flex-end;
      position: absolute;
      right: 2%;

      @media only screen and (max-width: 700px) {
        display: none;
      }
    }
  }
  .barcode_container {
    background: white;
    padding: 12px 8px 12px 24px;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    align-items: center;
    .barcode {
      transition: transform 0.5s ease-in-out;
      transform-origin: bottom right;

      &:hover {
        transform: scale(1.35);
      }
    }

    @media only screen and (max-width: 960px) {
      margin-top: -4rem;
      flex-direction: column; /* Set flex direction to column for tablet and phone sizes */
      .barcode {
        display: none;
      }
    }
    @include mq(640px) {
      width: 80%;
    }
    .barcode-container-text-area {
      margin-bottom: 2rem;
      text-align: center;
      .barcode-container-header {
        color: #1e1e1e;
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0em;
      }

      .barcode-container-text {
        color: #494949;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        margin-top: 0.5rem;
      }
    }

    .link_container {
      gap: 2rem;
      display: none;
      @media only screen and (max-width: 960px) {
        display: flex;
      }
      @include mq(640px) {
        flex-direction: column;
        gap: 1rem;
      }
    }
  }

  .barcode-container {
    @media screen and (max-width: 1260px) {
      width: 345px;
      height: 171px;
      // top: 457px;
      // left: 24px;
      // padding: 16px 24px 16px 24px;
      border-radius: 12px;
      // gap: 32px;
      flex-direction: column;
      margin-top: 0;
    }

    .barcode-image {
      background-image: url("../../../assets/images/qr-code.png");
      width: 200px;
      height: 250px;
      background-position: center;
      background-repeat: no-repeat;
      transform: translateY(-40px);
      display: block;

      @media screen and (max-width: 500px) {
        display: none;
      }
    }

    .barcode-container-text-area {
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      transform: translateY(60px);

      @media screen and (max-width: 500px) {
        transform: translateY(0px);
        padding-left: 50px;
      }
    }

    .barcode-container-header {
      color: #1e1e1e;
      font-size: 20px;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: left;
    }

    .barcode-container-text {
      color: #494949;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }

    .flex-col {
      display: flex;
      flex-direction: row;
    }

    .barcode-appstore {
      background-image: url("../../../assets/images/app-store-black.png");
      width: 200px;
      height: 50px;
      background-position: center;
      background-repeat: no-repeat;
      @media screen and (max-width: 500px) {
        display: block;
      }
    }

    .barcode-playstore {
      background-image: url("../../../assets/images/google-play-black.png");
      width: 200px;
      height: 50px;
      background-position: center;
      background-repeat: no-repeat;
      display: block;
    }
  }
  .connecting {
    font-size: 64px;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: 0em;
    text-align: center;
    @include container;
    transform: translateY(-100px);
    height: auto;
    max-height: 100dvh;
    margin-top: -8rem;
    @media screen and (max-width: 850px) {
      font-size: 52px;
      line-height: 60px;
      width: 90%;
    }
    @media screen and (max-width: 768px) {
      text-align: center;
      font-size: 50px;
      line-height: 60px;
    }

    @media screen and (max-width: 640px) {
      margin-top: 150px;
      text-align: center;
      font-size: 38px;
      line-height: 50px;
      width: 100%;
    }

    @keyframes fade-in-out-animation {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    .red-text {
      color: #ca094f;
    }
  }

  .earn-commision {
    font-weight: 400;
    font-size: 20px;
    line-height: 188.7%;
    color: #f3f3f3;
    text-align: center;
    margin-top: -4rem !important;
    line-height: 25px;
    @include container;
    @media only screen and (max-width: 960px) {
      margin-top: 0px !important;
      margin-bottom: 8rem;
    }
    @media only screen and (max-width: 768px) {
      text-align: center;
      font-size: 16px;
      line-height: 20.16px;
      margin-top: -10rem !important;
      br {
        display: none;
      }
    }

    @media only screen and (max-width: 640px) {
      text-align: center;
      font-size: 16px;
      line-height: 20.16px;
      transform: translateY(-80px);
      margin-top: -5rem !important;
      margin-bottom: 0;
    }

    @media only screen and (max-width: 400px) {
      transform: translateY(-130px);
    }
  }

  .financial-initiation-container {
    flex-direction: column;
    background-color: #fefefe;
    width: 100%;
    height: 782px;
  }

  .par-one-right {
    flex-direction: column;
    position: relative;
    width: 50%;
    animation: slideInFromRight 1s ease-in;

    @media only screen and (max-width: 924px) {
      display: none;
    }

    .primary-rectangle {
      position: relative;
      background-color: $app-primary;
      width: 536px;
      height: 468px;
      border-radius: 51px;
      transform: translate(30px, 35px);

      @media only screen and (max-width: 1200px) {
        width: 450px;
        height: 450px;
      }
    }

    .man-paying {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 100;
      background: url("../../../assets/images/man-paying-for-product.jpg");
      border-radius: 51px;
      background-repeat: no-repeat;
      // background-position: center;
    }

    .container {
      // width: 599px;
      // height: 478.38px;
      width: 720px;
      height: 458.38px;

      position: relative;
      margin: 30px;

      @media only screen and (max-width: 1024px) {
        height: auto;
        width: auto;
      }
    }

    .box {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 28px;
      left: 0;
      border-radius: 110px;
      // border-radius: 52.1314px;
      background: #e9b135;
      z-index: -1;

      @media only screen and (max-width: 1024px) {
        top: 22px;
        border-radius: 85px;
      }
    }

    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .overlay {
      // z-index: 10000000;
      width: 100%;
      height: 100%;
      // width: 105%;
      // margin-left: -12px;
      // margin-top: -30px;
      // background: #e9b135;
      // border-radius: 60px;
      // border-radius: 10px;
    }
  }
}
