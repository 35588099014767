@import "../../../styles/sass/_variables.scss";

.accordion {
  background-color: #fefefe;
  padding: 20px 0;
  background-image: url("../../../../public/images/faq-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;

  .accordion-container {
    // max-width: 1174px;
    width: 100%;
    height: auto;
    margin: 20px auto;
    padding: 0 20px;
    align-content: center;
    align-items: center;
    transition: all 5s ease-in-out;
    display: flex;
    flex-direction: column;
    .accordion-box {
      box-shadow: 0px 2.4px 4.8px rgba(0, 0, 0, 0.07),
        /* First shadow */ 0px 0.9px 7px rgba(0, 0, 0, 0.07); /* Second shadow */
      display: block;
      height: auto;
      transition: height 5s ease-in-out;
      .question {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
    .faq {
      font-style: normal;
      font-weight: 800;
      font-size: 40px;
      line-height: 54px;
      font-feature-settings: "salt" on, "liga" off;
      color: $color-text;
      padding: 40px 0;

      @media only screen and (max-width: 768px) {
        font-size: 30px;
        line-height: 40px;
      }
    }

    .mr-5 {
      margin-right: 5px;
    }
    .header {
      font-style: normal;
      font-weight: bold;
      font-size: 21.4415px;
      line-height: 25px;
      font-feature-settings: "salt" on, "liga" off;
      color: #20202f;
      text-align: left;

      @media only screen and (max-width: 768px) {
        font-size: 15px;
      }
    }
    .headerbtn {
      background-color: $app-primary;
      border-radius: 50%;
      font-size: 22px;
      display: flex;
      align-items: center;
      font-weight: bold;
      padding: 2px;
      height: 20px;
      width: 20px;
    }

    .sub-header {
      font-style: normal;
      font-weight: normal;
      font-size: 17.6683px;
      line-height: 30px;
      font-feature-settings: "salt" on, "liga" off;
      color: #18191f;
      text-align: left;
      padding: 10px 0;
      animation: fadeInOpacity 0.6s ease-in-out;
      width: 97%;
    }

    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .accordion-box {
      padding: 25px 30px;
      margin-top: 24px;
      align-items: center;
      align-content: center;
      width: 100%;
      height: auto;
      background-color: $color-white;
      cursor: pointer;
      align-self: center;
      border-radius: 10px;

      @media only screen and (max-width: 768px) {
        width: 500px;
      }
      @media only screen and (max-width: 480px) {
        width: 330px;
      }
      @media only screen and (max-width: 300px) {
        width: 250px;
      }
    }
    .orange-text {
      font-family: Poppins;
      font-weight: 800;
      font-size: 41px;
      line-height: 61px;
      letter-spacing: -0.03em;
      color: $app-primary;
    }

    .footer {
      font-family: Poppins;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: $color-black;
      max-width: 80%;
      margin: 50px auto;

      .orange-text {
        color: $app-primary;
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 0em;
      }

      @media only screen and (max-width: 768px) {
        font-size: 19px;
      }
    }

    .email {
      color: $app-primary;
    }
  }
  .faq_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .per-item {
      width: 80%;
      max-width: 2000px;
      @include mq(820px) {
        width: 90%;
      }
    }
  }

  .vertical-align {
    vertical-align: middle;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
