@import "../../styles/sass/_variables.scss";

.about-us-container {
  background: linear-gradient(
    0deg,
    rgba(255, 189, 43, 0.3) 19.95%,
    rgba(255, 240, 232, 0.3) 100%
  );
  height: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;

  .top-text-area {
    // padding: 100px;
    padding: 200px 40px 40px 40px;
    display: flex;
    width: 90%;
    margin: auto;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: 924px) {
      flex-direction: column;
      text-align: center;
      align-items: center;
      gap: 3rem;
      padding-top: 120px;
    }
    @media only screen and (max-width: 640px) {
      padding: 120px 16px 80px 16px;
    }
  }

  .our-vision {
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 50px;
    border-radius: 12px;
    background-color: #fefefe;

    color: var(--regxta-gold, #e3a721);

    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .left-container {
    text-align: left;
    width: 45%;
    @media only screen and (max-width: 768px) {
      margin-left: 0px;
      text-align: center;
      justify-self: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .left-container-text {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    max-width: 470px;
    width: auto;
    // min-height: 200px;
    display: flex;

    @media only screen and (max-width: 768px) {
      margin-left: 0px;
      text-align: center;
      width: 100%;
      margin: auto;
      font-size: 32px;
    }
  }
  .right-container {
    text-align: left;
    height: auto;
    width: 55%;

    @media only screen and (max-width: 924px) {
      margin-left: 0px;
      text-align: center;
      width: 100%;
      height: auto;
    }

    .right-container-header {
      font-size: 40px;

      @media only screen and (max-width: 924px) {
        margin-top: 20px;
        // text-align: center;
      }
    }

    .right-container-text {
      margin-top: 10px;
      font-size: 20px;
      // max-width: 100%;
      width: auto;
      display: flex;
      width: 100%;
      height: auto;

      @media only screen and (max-width: 768px) {
        font-size: 18px;
      }
    }
  }

  .collage-container {
    padding: 40px;
    max-width: 100%;
    margin: 0 auto;

    @media only screen and (max-width: 640px) {
      padding: 20px;
    }

    .collage-image {
      max-width: 100%;
      height: auto;
      @media only screen and (max-width: 640px) {
        border-radius: 12px;
      }
    }
  }

  .short-story {
    background-color: #1e1e1e;
    margin-top: 50px;
    width: 100%;
    height: auto;
    padding: 100px 5%;
    display: flex;
    justify-content: space-evenly;
    gap: 5rem;
    background-image: url("../../../public/images/exclude.svg");
    background-repeat: no-repeat;
    background-size: cover;
    @media only screen and (max-width: 1280px) {
      justify-content: space-between;
    }
    @media only screen and (max-width: 1024px) {
      justify-content: space-between;
      flex-direction: column;
    }
    @media only screen and (max-width: 924px) {
      flex-direction: column;
      padding: 100px 30px;
    }

    .afis-and-rukky {
      height: auto;
      // width: 50%;
      max-width: 100%;
      margin: 0 auto;
    }

    .afis-and-rukky-image {
      height: auto;
      width: 30%;
      img {
        border-radius: 20px;
        width: auto;
        @media only screen and (max-width: 1024px) {
          width: 100%;
        }
      }
      @media only screen and (max-width: 1024px) {
        width: 100%;
      }
    }

    .para-right {
      display: flex;
      flex-direction: column;
      width: 45%;

      @media only screen and (max-width: 1024px) {
        text-align: center;
        width: 100%;
      }

      .para-right-header {
        color: #fafafa;
        font-size: 40px;
        font-weight: 700;
        text-transform: capitalize;
        padding: 0 0 30px 0;
      }

      .para-right-text {
        color: #f3f3f3;
        font-size: 20px;
      }
    }
  }
  .quote-container {
    color: #fff; /* White text */
    padding: 20px;
    border-radius: 8px;
    font-family: "Arial", sans-serif; /* Replace with your desired font */
    position: relative;
    .quote-icon {
      font-size: 8rem;
      position: absolute;
      top: 10px;
      left: -20px;
      color: #ffffff80;
    }

    .quote-text {
      font-size: 24px;
      line-height: 30px;
      font-weight: 600;
      font-style: italic;
      color: #f3f3f3;
    }

    .quote-author {
      font-size: 20px;
      line-height: 25px;
      margin-top: 10px;
      color: #f3f3f3; /* Slightly lighter color for the author */
    }
  }

  .awards-and-leadership {
    background: white;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12rem 2.5%;
    padding-bottom: 0 !important;
    @media only screen and(max-width:760px) {
      padding: 6rem 20px;
      // margin-top: 1rem;
    }
    .awards-header {
      color: #1e1e1e;
      text-align: center;
      font-size: 40px;
      font-weight: 700;
    }
    .awards-text {
      color: #494949;
      text-align: center;
      font-size: 20px;
      margin-top: 15px;
    }

    .awards {
      display: flex;
      margin-top: 50px;
      padding: 50px;
      justify-content: center;
      flex-wrap: wrap;
      gap: 40px;

      @media only screen and (max-width: 760px) {
        flex-direction: column;
        gap: 30px;
      }
    }

    .leadership {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
      margin-top: 50px;
      width: 90%;
      @media only screen and (max-width: 924px) {
        width: 100%;
      }

      .leadership-header {
        color: #1e1e1e;
        text-align: center;
        font-size: 40px;
        font-weight: 700;
        margin-top: 100px;
      }
      .leadership-text {
        color: #494949;
        text-align: center;
        font-size: 20px;
        width: 100%;
        height: auto;
        padding: 16px 0 48px 0;
        @media only screen and (max-width: 924px) {
          flex-direction: column;
          width: 100%;
        }
        @media only screen and (max-width: 640px) {
          padding: 40px 0;
          font-size: 16px;
        }
      }

      .image-container {
        display: flex;
        width: auto;
        flex-wrap: wrap;
        justify-content: center;
        gap: 2%;
        width: 90%;
        margin: auto;
        @media only screen and (max-width: 924px) {
          gap: 3rem;
        }
        @media only screen and (max-width: 640px) {
          text-align: center;
          flex-direction: column;
          width: 100%;
        }
        .single-image-container {
          display: flex;
          flex-direction: column;
          max-width: 100%;
          width: 20%;
          height: auto;
          @media only screen and (max-width: 924px) {
            width: 30%;
          }
          @media only screen and (max-width: 640px) {
            flex-direction: row;
            width: 100%;
            gap: 2rem;
          }
          .leader-image {
            max-width: 100%;
            width: auto;
            height: auto;

            @media only screen and (max-width: 924px) {
              max-width: 100%;
            }
            @media only screen and (max-width: 640px) {
              width: 40%;
            }
          }

          // @media only screen and (max-width: 924px) {
          //   flex-direction: row;
          //   padding-right: 0px;
          //   margin-bottom: 20px;
          //   max-width: 80%;
          //   display: flex;
          //   align-items: center;
          // }
          // @media only screen and (max-width: 924px) {
          //   flex-direction: column;
          //   padding-right: 0px;
          //   margin-bottom: 20px;
          //   max-width: 100%;
          //   display: flex;
          //   align-items: left;
          // }

          .leader-description {
            display: flex;
            flex-direction: row;
            text-align: left;
            align-items: flex-start;
            justify-content: space-between;
            max-width: auto;

            @media only screen and (max-width: 768px) {
              flex-direction: column;
              padding-right: 0px;
              justify-content: space-between;
            }

            .mt-10 {
              margin-top: 15px;
            }
          }

          .flex-start {
            align-items: flex-start;
          }

          .right-linkedin {
            display: flex;
            align-self: flex-end;

            @media only screen and (max-width: 924px) {
              flex-direction: column;
              padding-right: 0px;
              justify-content: space-between;
            }
          }

          .leader-name {
            color: #1e1e1e;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding-top: 15px;

            @media only screen and (max-width: 924px) {
              font-size: 16px;
              padding-top: 0px;
              width: 200px;
            }
          }

          .leader-title {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-top: 10px;
            display: flex;

            @media only screen and (max-width: 924px) {
              flex-direction: column;
              padding-right: 0px;
            }
          }
        }
      }
    }

    .investors {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 12rem;
      @include mq(640px) {
        margin: 6rem 1.5rem;
      }

      .investors-header {
        // margin-top: 200px;
        color: #1e1e1e;
        text-align: center;
        font-size: 40px;
        font-weight: 700;
      }
      .investors-text {
        margin-top: 20px;
        color: #494949;
        text-align: center;
        font-size: 20px;
        // margin-bottom: 50px;
      }

      .investors-logos {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        background-color: #ffffff;
        border: 1px solid #000000;
        border-radius: 12px;
        padding: 10px;
        width: 100%;
        max-width: 100%;
        margin: 30px auto 0;
        @media only screen and (max-width: 1024px) {
          width: 90%;
        }
        @media only screen and (max-width: 524px) {
          width: 100%;
        }
      }

      .investors-logos img {
        max-width: 100%;
        height: auto;
        margin: 5px;
        height: calc(100% - 5px);
      }
    }
  }
  .pd_press_section {
    background-color: white;
    width: 100%;
    padding-bottom: 40px;
    .home-marq {
      // @include container;
      max-width: 1620px;
      margin: auto;
      display: flex;
      .overlay {
        --gradient-color: rgba(255, 255, 255, 1), rgba(255, 255, 255, 0);
        --gradient-width: 700px;
        @include mq(640px) {
          --gradient-width: 50px !important;
        }
      }
    }
    @include mq(640px) {
      margin-top: 50px;
      margin-bottom: 20px;
    }
    .item_wrapper {
      margin: 0 3rem;
    }

    .logo_flex {
      display: flex;
      align-items: center;
      margin-top: 50px;
      .overlay {
        --gradient-width: 100px !important;
        @include mq(640px) {
          --gradient-width: 50px !important;
        }
      }
    }
  }
}
