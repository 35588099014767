@import "../../../styles/sass/_variables.scss";

.footer-section {
  padding-top: 50px;
  width: 100%;
  background-color: #1e1e1e;
  height: auto;
}

.footer-container {
  @include container;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;
  // display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 1024px) {
    // width: 100%;
    padding: 30px;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    // width: 100%;
  }

  .section-right {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 42px;
    @media only screen and (max-width: 760px) {
      flex-wrap: wrap;
      width: 100%;
    }
    div {
      width: 45%;
    }
  }

  .regxta-logo {
    width: 50%;
    @media only screen and (max-width: 768px) {
      width: 30%;
      text-align: center;
    }
  }

  .footer-col-1 {
    text-align: left;

    .footer-col-1-header {
      margin-top: 24px;
      line-height: 20px;
      font-size: 20px;
      font-weight: 400;
      color: white;
      @media only screen and (max-width: 768) {
      }
    }
    .footer-col-1-sub-header {
      font-weight: bold;
      font-size: 15px;
      color: white;
      margin-bottom: 30px;
      margin-top: 7px;
    }
    .address {
      font-size: 15px;
      color: white;
      margin-bottom: 30px;
      a {
        color: wheat;
      }
    }
    .footer-col-1-text {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 23px;
      font-feature-settings: "salt" on, "liga" off;
      color: #fcffff;
      margin: 20px 0;
    }
  }

  .footer-col-2 {
    text-align: left;

    .footer-col-2-header {
      line-height: 30px;
      font-size: 14px;
      font-weight: 700;
      color: #959595;
      margin-bottom: 30px;
    }

    .footer-col-2-text {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 23px;
      font-feature-settings: "salt" on, "liga" off;
      color: #f3f3f3;
      margin: 20px 0;
      display: block;
    }
  }

  .footer-col-3 {
    text-align: left;

    .footer-col-3-header {
      line-height: 30px;
      font-size: 20px;
      color: white;
      margin-bottom: 30px;
    }

    .footer-col-3-text {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 23px;
      font-feature-settings: "salt" on, "liga" off;
      color: #fcffff;
      margin: 20px 0;
    }
  }

  .footer-col-4 {
    text-align: left;
    @include mq(840px) {
      display: none;
    }

    .footer-col-4-header {
      line-height: 30px;
      font-size: 20px;
      color: white;
      margin-bottom: 30px;
    }

    .footer-col-4-text {
      margin-top: 20px;
      font-size: 15px;
      color: #d9dbe1;
      height: 20px;
      padding: 2px 0;
    }

    .footer-col-5-header {
      line-height: 30px;
      font-size: 20px;
      color: white;
      margin-bottom: 25px;
      margin-top: 30px;
    }

    .play-store {
      width: 50%;
      height: 50%;
      cursor: pointer;
    }

    .mr {
      margin-right: 10px;
    }

    .footer-input {
      background-color: #4d4d59;
      padding: 5px;
      border-radius: 8px;
      width: fit-content;
      display: flex;
      align-items: center;
      input {
        background-color: transparent;
        padding: 5px;
        color: #fff;
        border: none;
      }
      input::placeholder {
        color: white;
      }
    }
  }

  .flex-center {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-left: auto;
    gap: 20px;

    @media only screen and (max-width: 768px) {
      justify-content: flex-start;
      align-items: start;
      gap: 10px;
      margin: 0;
      margin-top: 20px;
    }
    .play-store {
      @media only screen and (max-width: 760px) {
        // width: 25%; in case we put app store logo back use this one
        width: 100%;
        padding-top: 30px;
      }
    }
    .footer-apps {
      @media only screen and (max-width: 480px) {
        width: 140px;
      }
    }
  }

  .horizontal-line {
    border: none;
    border-top: 1px solid white;
    margin: 10px 0;
  }

  .socials {
    display: flex;
    padding-top: 34px;
    width: auto;
    @include mq(640px) {
      margin-bottom: 2rem;
    }

    .icon {
      text-align: center;
      vertical-align: baseline;
      color: $color-white;
      font-size: 15px;
      border-radius: 20px;
      margin-right: 40px;
      margin-bottom: 10px;
    }
  }

  .footer-bottom-text {
    line-height: 20px;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    color: #f3f3f3;
    display: flex;
    text-align: start;
    margin-top: 30px;
    @include mq(640px) {
      font-size: 14px;
      text-align: center;
    }
  }

  .phone-image {
    justify-self: flex-end;
    justify-content: flex-end;
    &.sm-phone {
      display: none;
      @media only screen and (max-width: 640px) {
        display: block;
        display: flex;
        justify-content: center;
      }
    }

    @media only screen and (max-width: 780px) {
      // display: none;
    }
  }
}
.flex-social {
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
}
