@import "../../../styles/sass/_variables.scss";

.trusted-by-container {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5rem 0;
  @media only screen and (max-width: 820px) {
    // padding: 30px 0;
    margin: 3rem 0;
  }
  .phone-info-box {
    background-color: #1e1e1e;
    @include container;
    height: auto;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 60px;
    margin-top: 4rem;
    background-image: url("../../../../public/images/footer-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    @media only screen and (max-width: 820px) {
      width: 100%;
      border-radius: 0;
    }
    @media only screen and (max-width: 524px) {
      display: flex;
      width: 100%;
      align-content: center;
      // padding-left: 0px;
      // padding-right: 0px;
      padding-bottom: 60px;
    }

    .phone {
      // height: 488px;
      @media only screen and (max-width: 767px) {
        display: none;
      }
    }
    .phone-info-box-text-area {
      // padding-left: 30px;
      width: auto;
      height: 500px;
      text-align: left;
      align-content: flex-start;
      .phone-sm {
        display: none;
      }
      @media only screen and (max-width: 820px) {
        height: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      @media only screen and (max-width: 640px) {
        .phone-sm {
          display: block;
        }
        height: auto;
        width: 100%;
      }

      .phone-info-box-header {
        font-size: 40px;
        font-weight: 700;
        line-height: 50px;
        letter-spacing: 0em;
        text-align: left;
        color: #fefefe;
        @media only screen and (max-width: 1024px) {
          font-size: 34px;
        }
        @media only screen and (max-width: 786px) {
          text-align: center;
          font-size: 20px;
          line-height: 25px;
        }
        @media only screen and (max-width: 640px) {
          margin-top: 3rem;
        }
      }
      .phone-info-box-text {
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        color: #fefefe;
        padding-top: 30px;
        padding-bottom: 30px;

        @media only screen and (max-width: 786px) {
          text-align: center;
          font-size: 16px;
        }
      }

      .subtext-area {
        margin-top: 2rem;
        .subtext-header {
          font-size: 30px;
          font-weight: 500;
          line-height: 40px;
          letter-spacing: 0em;
          text-align: left;
          color: #fefefe;

          @media only screen and (max-width: 786px) {
            text-align: center;
            font-size: 16px;
            line-height: 20px;
          }
        }

        .subtext {
          font-size: 20px;
          font-weight: 400;
          line-height: 25px;
          letter-spacing: 0em;
          text-align: left;
          color: #fefefe;
          @media only screen and (max-width: 786px) {
            text-align: center;
            font-size: 16px;
          }
        }
      }
    }
  }
  .trusted-by {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-self: baseline;
    margin-top: 100px;
    // justify-content: center;
    // align-items: center;

    .trusted-by-header {
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: 0em;
      text-align: center;
      padding: 20px 0;
      margin-bottom: 3rem;
      @media only screen and (max-width: 640px) {
        font-size: 30px;
        margin-bottom: 2rem;
      }
    }

    .logo-row {
      display: flex;
      @include container;
      flex-direction: row;
      justify-content: space-between;

      @media only screen and (max-width: 924px) {
        flex-wrap: wrap;
        width: 80%;
        margin: auto;
        gap: 1rem;
      }
      .logo {
        margin: auto;
        @media only screen and (max-width: 640px) {
          width: 100px;
          margin-bottom: 1.5rem;
        }
      }
    }
  }
  .barcode_container {
    margin-top: 1rem;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    align-items: center;
    .barcode {
      transition: transform 0.5s ease-in-out;
      transform-origin: top left;

      &:hover {
        transform: scale(1.2);
      }
    }

    @media only screen and (max-width: 960px) {
      flex-direction: column; /* Set flex direction to column for tablet and phone sizes */
      .barcode {
        display: none;
      }
    }
    .barcode-container-text-area {
      margin-bottom: 2rem;
      text-align: center;
      .barcode-container-header {
        color: #1e1e1e;
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0em;
      }

      .barcode-container-text {
        color: #494949;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        margin-top: 0.5rem;
      }
    }

    .link_container {
      gap: 2rem;
      display: none;
      @media only screen and (max-width: 960px) {
        display: flex;
      }
    }
  }
}
.download_links {
  display: flex;
  gap: 20px;
  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
  }
  .download_icons {
    width: 160px;
  }
}
.barcode-image {
  background-image: url("../../../assets/images/qr-code.png");
  width: 200px;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  transform: translateY(-40px);
  margin-top: 20px;
  display: block;
  @media screen and (max-width: 500px) {
    display: none;
    padding-left: 30px;
  }
}

.flex-col {
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
}

.appstore-image {
  background-image: url("../../../assets/images/appstore.png");
  width: 200px;
  height: 50px;
  background-position: center;
  background-repeat: no-repeat;
  display: none;

  @media screen and (max-width: 500px) {
    display: block;
    margin-top: 20px;
  }
}

.playstore-image {
  background-image: url("../../../assets/images/googlePlay.png");
  width: 200px;
  height: 50px;
  background-position: center;
  background-repeat: no-repeat;
  display: block;

  // @media screen and (max-width: 500px) {
  //   display: block;
  //   margin-top: 20px;
  // }
}
