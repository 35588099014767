.blogWrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.blogWrap div{
    width: 45%;
}

.blogWrap h2{
    font-size: 36px;
    line-height: 45.36px;
    font-weight: 600px;
}
.blogWrap p {
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 25.2px !important;

     @media only screen and (max-width: 768px){
        font-size: 16px;
     }
}
.blogWrap main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    margin-top: 20.16px;
}
.blogWrap main span {
    display: flex;
    align-items: center;
    gap: 10px;
}
.blogWrap main span img{
    height: 20px;
    width: 20px;
}
.blogWrap main span small{
color: #E3A721;
}
@media only screen and (max-width: 800px){
.blogWrap{
    flex-direction: column;
}
.blogWrap div{
    width: 100%;
}
.blogImage img{
    width: 90%;
}
}