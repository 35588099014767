@import "../../styles/sass/variables";
.navWrap {
  background: #fefefe;
  position: fixed;
  @include container;
  min-height: 80px;
  max-height: auto;
  left: 10%;
  right: 10%;
  top: 18px;
  z-index: 1000;
  background: #fefefe;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding-top: 0.7rem;
  @include mq(1024px) {
    left: 5%;
    right: 5%;
  }
  @media only screen and (max-width: 768px) {
    top: 0%;
    width: 100%;
    left: 0.3%;
    min-height: 60px;
  }
  @media only screen and (max-width: 500px) {
    background: #fefefe;
    width: 100%;
    left: 0%;
    top: 0px;
    background: #fefefe;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 0;
  }
}
.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 32px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    gap: 23px;
    .logo_mobile {
      margin-top: 1rem;
      margin-left: -2rem;
    }
  }

  .nav-item_container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      margin: 12px 0;
      display: none;
    }
  }

  .nav-item {
    font-style: normal;
    font-size: 17px;
    line-height: 16px;
    font-family: "DM Sans", sans-serif;
    font-weight: 500;
    padding: 12px 20px;
    color: $color-black;
    display: flex;
    align-items: center;
    .arrow {
      display: inline-block;
      margin-left: 5px;
      transition: transform 0.3s ease;
    }
    &.hover-effect {
      background-color: $color-grey;
      border-radius: 12px;
      .arrow {
        transform: rotate(180deg);
      }
    }
    &:hover {
      cursor: pointer;
      color: $app-primary;
      background-color: $color-grey;
      border-radius: 12px;
    }
    @media only screen and (max-width: 768px) {
      padding: 12px 8px;
    }

    a {
      color: $color-black;

      &:hover,
      &:active {
        color: $app-primary;
      }
    }

    .dropdown-content {
      width: auto;
      height: auto;
      padding: 40px;
      background-color: #fefefe;
      display: none;
    }
    &:hover .dropdown-content {
      margin-top: 100px;
      padding: 20px;
      display: block;
      flex-direction: row;
      transition: all 4s ease;
    }
  }

  .btn {
    background-color: $app-primary;
    border-radius: 30px;
    color: $color-white;
    padding: 15px 30px;
    font-size: 17.5px;
    border: none;
    cursor: pointer;
  }

  .burger {
    display: none;
    cursor: pointer;
    @media only screen and (max-width: 768px) {
      display: block;
      position: absolute;
      top: 15px;
      right: 20px;
    }
  }

  .toggle-item {
    display: flex;
  }

  .flag-icon {
    align-self: right;

    @media only screen and (max-width: 768px) {
      padding: 12px 0;
      display: none;
    }
  }
}
.dropy li {
  list-style: none;
}
.dropy {
  height: 0;
  opacity: 0; /* Initially hidden */
  transition: max-height 0.5s ease-out, opacity 0.5s ease-out; /* Smooth transition */
  &.show-dropy {
    opacity: 1;
    width: 100%;
    height: auto;
    padding-bottom: 2rem;
  }
}
.drop {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 15px;
  text-align: left;
  line-height: 22px;
  gap: 20px;
  align-items: baseline;

  p {
    font-size: 12px;
    margin-top: 20px;
  }
  @media only screen and (max-width: 760px) {
    flex-direction: column;
  }
}
.dropy h1 {
  text-align: left;
  width: 95%;
  margin: auto;
}
.drop h2 {
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    height: 24px;
    width: 24px;
  }
  .footer-col-2-text {
    color: black;
    font-size: 13.5px;

    &:hover {
      color: $app-primary;
    }
  }
}
.drop li {
  width: 18%;

  @media only screen and(max-width: 768px) {
    width: 100%;
  }
}
