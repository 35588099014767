@import "../../../styles/sass/_variables.scss";

.client {
  background: linear-gradient(107.99deg, #fff4d8 11.74%, #e5deff 84.51%);
  position: relative;

  .icon {
    position: absolute;
    top: 50px;
    right: 280px;
    opacity: 0.22;

    @media only screen and (max-width: 1440px) {
      top: 60px;
      right: 200px;
    }

    @media only screen and (max-width: 1024px) {
      top: 70px;
      right: 50px;
    }

    @media only screen and (max-width: 768px) {
      opacity: 0;
    }
  }
  .our-clients-container {
    @include container;
    height: auto;
    margin: 0 auto;
    padding: 60px 0;
    align-items: center;

    @media only screen and (max-width: 1024px) {
      width: 90%;
    }
    .content_wrapper {
      width: 100%;
      @include mq(640px) {
        height: 585px;
        overflow-y: scroll;
      }
      .flex {
        padding: 1.5rem 5%;
        display: flex;
        justify-content: space-between;
        gap: 3rem;
        @include mq(960px) {
          padding: 1.5rem 0;
        }
        @include mq(640px) {
          flex-direction: column;
        }
        .box {
          width: 49%;
          height: 176px;
          border: 3px solid #212121;
          border-radius: 8px;
          border-top: 0;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          padding: 16px 32px;
          display: flex;
          flex-direction: column;
          height: auto;
          gap: 2rem;
          transition: transform 0.5s ease-in-out;
          transform-origin: bottom right;

          &:hover {
            transform: scale(1.2);
            background-color: white;
            border: 3px solid #212121;
            border-radius: 8px;
            @include mq(640px) {
              transform: none !important;
            }
          }
          @include mq(640px) {
            &:hover {
              transform: none !important;
            }
          }
          &.box-right {
            transition: transform 0.5s ease-in-out;
            transform-origin: bottom left;
            &:hover {
              transform: scale(1.2);
            }
          }
          @include mq(640px) {
            width: 100%;
            border: 3px solid #212121 !important;
            border-radius: 8px !important;
          }
          &.flip {
            border-bottom: 0;
            border-top: 3px solid #212121;
            border-radius: 8px;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            &:hover {
              border: 3px solid #212121;
              border-radius: 8px;
            }
          }
          .text-wrapper {
            .title {
              font-size: 16px;
              font-weight: 400;
              text-align: left;
            }
          }
          .footer {
            display: flex;
            margin-left: -10px;
            align-items: center;
            margin-top: auto;
            .name {
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 20px;
              text-align: center;
              color: #e3a721;
            }

            .image {
              img {
                width: 75%;
                height: auto; /* Remove fixed height */
                border-radius: 50%;
              }
            }
          }
        }
      }
    }

    h1 {
      font-style: normal;
      font-weight: 800;
      font-size: 48px;
      line-height: 64px;
      color: #1e1e1e;

      margin-bottom: 40px;

      @media only screen and (max-width: 425px) {
        font-size: 30px;
        line-height: 40px;
      }
    }
  }
}
