@import "../../styles/sass/_variables.scss";

.vision-container {
  display: flex;
  //   flex-wrap: wrap;
  gap: 20px;
  border-radius: 8px;
  font-family: Arial, sans-serif;
  @include container;
  margin-top: 200px;

  @include mq(1024px) {
    flex-wrap: wrap;
  }
  @include mq(768px) {
    margin-top: 100px;
  }
  .vision-content {
    flex: 1 1 45%;
    padding: 20px;
    background-color: white;
    border-radius: 16px;

    .vision-title {
      font-size: 20px;
      line-height: 25px;
      color: #e3a721;
      font-weight: 700;
    }

    .vision-header {
      font-size: 40px;
      font-weight: bold;
      margin: 20px 0;
      line-height: 50px;
      color: #212121;
      @include mq(768px) {
        font-size: 32px;
        line-height: 38px;
      }
      @include mq(480px) {
        font-size: 24px;
        line-height: 28px;
      }
    }

    .vision-description {
      font-size: 20px;
      color: #494949;
      margin-bottom: 10px;
      line-height: 25px;
    }
  }

  .vision-stats {
    flex: 1 1 55%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;

    .vision-images {
      display: flex;
      gap: 10px;
      height: 60%;
      .image-left {
        flex: 1;
        border-radius: 8px;
        object-fit: cover;
        height: 400px;
        width: 100%;
      }
    }

    .stats-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 23px;
      background-color: white;
      border-radius: 16px;
      padding: 24px;

      .stat-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 15px;
        border-radius: 8px;
        text-align: center;
        font-weight: bold;
        @include mq(640px) {
          align-items: center;
        }
        h4 {
          font-size: 32px;
          margin: 0;
          font-weight: 700;
          @include mq(640px) {
            font-size: 24px;
          }
        }

        p {
          font-size: 16px;
          margin: 12px 0 0;
          font-weight: 400;
        }

        &.yellow {
          background-color: #ffffc8;
        }
        &.sea-green {
          background-color: #cffdff;
        }

        &.purple {
          background-color: #f3dfff;
        }

        &.green {
          background-color: #c7ffc5;
        }
      }
    }
  }
}
