@import "../../../styles/sass/_variables.scss";

.content1 {
  margin-top: 10rem;
  .header_wrapper {
    h1 {
      font-size: 40px;
      font-weight: 600;
      line-height: 50px;
      @media only screen and (max-width: 768px) {
        font-size: 32px;
      }
    }
    p {
      font-size: 20px;
      line-height: 25px;
      @include container;
      @media only screen and (max-width: 768px) {
        font-size: 18px;
      }
      span {
        font-weight: 700;
      }
    }
  }
}
.why-we-different-container {
  display: flex;
  background-color: #fefefe;
  @include container;
  gap: 3rem;
  margin: auto;
  margin: 50px auto;
  align-items: center;
  justify-content: space-between;
  &.diff-fin {
    @media only screen and (max-width: 960px) {
      flex-direction: column-reverse;
    }
  }

  @media only screen and (max-width: 960px) {
    width: 100%;
    padding: 30px;
    flex-direction: column;
    br {
      display: none;
    }
  }

  @media only screen and (max-width: 640px) {
    width: 100%;
    padding: 24px;
    flex-direction: column;
  }
  .why-we-different-par-one-right-header {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    margin-bottom: 30px;
    text-align: left;
    color: #1e1e1e;
    @media only screen and (max-width: 640px) {
      font-size: 24px;
      text-align: center;
      margin-bottom: 0;
    }
  }
  .why-we-different-par-one-right-text {
    // width: 70%;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
    color: #494949;
    @media only screen and (max-width: 500px) {
      font-size: 16px;
    }
  }

  .why-we-different-image {
    max-width: 100%;
    height: auto;
    width: 533px;
    height: 553px;
    @media (max-width: 786px) {
      width: 100%;
    }
  }
}

.why-we-different-text-area {
  flex-direction: column;
  align-self: auto;
  margin-top: 2rem;
  width: 50%;
  @media only screen and (max-width: 820px) {
    width: 100%;
  }
  .why-we-different-header {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    margin-bottom: 30px;
    text-align: left;
    color: #1e1e1e;
    @media only screen and (max-width: 1200px) {
      font-size: 28px;
    }
    @media only screen and (max-width: 640px) {
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      br {
        display: none;
      }
    }
  }
  .why-we-different-text {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
    color: #494949;
    @media only screen and (max-width: 1200px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 1200px) {
      margin: 4px 0;
    }
  }
}
.tick-icon {
  margin-right: 10px;
}

.why-we-different-par-one-right {
  flex-direction: column;
  position: relative;
  width: auto;
  display: flex;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: auto;
    margin-left: revert-layer;
  }
  .why-we-different-par-one-right-header {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    margin-bottom: 30px;
    text-align: left;
    color: #1e1e1e;
    @media only screen and (max-width: 640px) {
      font-size: 24px;
      text-align: center;
      margin-bottom: 0;
    }
  }
  .why-we-different-par-one-right-text {
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
    color: #494949;
    @media only screen and (max-width: 500px) {
      font-size: 16px;
    }
  }

  .why-we-different-image {
    max-width: 100%;
    height: auto;
    @media (max-width: 786px) {
      width: 100%;
    }
  }

  // @media only screen and (max-width: 924px) {
  //   display: none;
  // }
}
