.wrapy{
    background: linear-gradient(165.03deg, #FFF5DF 0.01%, #DBD2FF 100%);
}
.Blowrap{
    padding-top: 200px;
    width: 88%;
    margin: auto;
    @media only screen and (max-width: 768px) {
            padding-top: 120px;
        }
}
.Blowrap h1{
    font-size: 40px !important;
    font-weight: 700 !important;
    line-height: 50.4px !important;
    @media only screen and (max-width: 768px) {
            font-size: 36px;
        }
}
.Blowrap p{
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 25.2px !important;
    color: #494949;
    @media only screen and (max-width: 768px) {
            font-size: 16px;
        }
}
.Blowrap div{
    margin: 30px 0;
}
@media only screen and (max-width: 1024px) {
.Blowrap{
    width: 95%;
}
}